import React, { useMemo } from "react";

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<T>;

function useBoundComponent<
  Props,
  BoundProps extends Pick<Props, Bound>,
  Bound extends keyof Props = keyof Props & keyof BoundProps
>(
  component: React.ComponentType<Props>,
  bindings: BoundProps
): React.FC<PartialBy<Props, Bound>> {
  // This `as` below allows us to work around the TypeScripts' inability
  // to properly elide the types here.
  const Component = component as React.ComponentType<
    PartialBy<Props, Bound> | Bound
  >;
  return useMemo(
    () => (props) => <Component {...props} {...bindings} />,
    [bindings, Component]
  );
}

export default useBoundComponent;
