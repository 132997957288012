import React from "react";
import { Routes, Route } from "react-router-dom";
import MainPage from "../pages/MainPage";
import NotFoundPage from "../pages/NotFoundPage";

const Root: React.FC = () => (
  <Routes>
    <Route index element={<MainPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default Root;
