type ApiCallParams = {
  url: string;
  method?: "GET" | "POST";
  requestBody?: unknown;
};
export const apiCall = async <T>({
  url,
  method = "GET",
  requestBody,
}: ApiCallParams): Promise<T> => {
  const requestInit: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  };

  const response = await fetch(url, requestInit);

  if (response.status !== 200) {
    throw new Error(`Server response status: ${response.status}`);
  }

  return response.json();
};
