import config from "../../config";
import { StateVariant } from "@tagged-state/core";
import { createContext, createTaggedStateHook } from "@tagged-state/react";

export type ConfigProps = Awaited<typeof config>;

export type State =
  | StateVariant<"uninit">
  | StateVariant<"pending">
  | StateVariant<"ready", ConfigProps>
  | StateVariant<"error", { error: Error }>;

const { Context, ComponentGuard } = createContext<State>({
  tag: "uninit",
  data: {},
});

export const ConfigContext = Context;
export const ConfigGuard = ComponentGuard;

export const useConfig = createTaggedStateHook(Context, "ready" as const);
