import { loadProduction } from "hybridconfig";

const baseUrlFormat = (url: string) => {
  return new URL(url).origin;
};

type AllowedKeys = "baseUrl";

const config = loadProduction<AllowedKeys>({
  remoteConfigPath: "/config.json",
  envKeyPrefix: "APP_",
}).then((config) => {
  const { baseUrl, ...rest } = config;

  return {
    baseUrl: baseUrlFormat(baseUrl),
    ...rest,
  };
});

export default config;
