import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  // Extend `Theme` with our custom params.
  interface Theme {}
  // Allow configuration using `createTheme`.
  interface ThemeOptions {}
}

const theme = createTheme({
  palette: {
    mode: "dark",
  },
  components: {
    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: "inherit",
        },
      },
    },
  },
});

export default theme;
