import { apiCall } from "./apiCall";

export type EthereumCheckResponse = {
  totalBlocksCount: number;
  blocksCount: { testnet1: number; testnet2: number; testnet3: number };
};

export const ethereumCheck = (url: string, address: string) =>
  apiCall<EthereumCheckResponse>({
    url: `${url}/ethereum/${address.toLowerCase()}`,
  });

export type SubstrateRecoverResponse = {
  blocksCount: { testnet1: number; testnet2: number; testnet3: number };
  ethereumAddressFound: {
    testnet1: boolean;
    testnet2: boolean;
    testnet3: boolean;
  };
};

export const substrateRecover = (url: string, address: string) =>
  apiCall<SubstrateRecoverResponse>({
    url: `${url}/substrate/${address}`,
  });
