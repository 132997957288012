import {
  Alert,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack } from "@mui/system";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import React, { PropsWithChildren, useCallback, useMemo } from "react";
import {
  ethereumCheck,
  EthereumCheckResponse,
  substrateRecover,
  SubstrateRecoverResponse,
} from "../api/check";
import { ConfigGuard, useConfig } from "../subsystem/config/state";
import { isHexString } from "../subsystem/utils/checkers";
import { DeepRequired } from "../subsystem/utils/types";

import ErrorPage from "./ErrorPage";
import Layout from "./Layout";

const ConfigLoading: React.FC = () => (
  <Layout>
    <CircularProgress />
    <Typography variant="body1">Loading the configuration file</Typography>
  </Layout>
);

const TroubleshootingFields: React.FC = () => {
  const { values, errors, handleChange } =
    useFormikContext<TroubleshootingFormValues>();

  const result = useMemo(() => {
    if (errors.substrate?.account) {
      return (
        <Alert variant="outlined" severity="error" icon={<CloseIcon />}>
          <Typography>{errors.substrate?.account}</Typography>
        </Alert>
      );
    }

    if (!values.result) {
      return null;
    }

    if (values.result.state === "found") {
      const { ethereumAddressFound, blocksCount } = values.result.data;

      return (
        <>
          <Alert variant="outlined" severity="info" icon={false}>
            Blocks produced by this validator:
            <ul>
              <li>Testnet 1: {blocksCount.testnet1}</li>
              <li>Testnet 2: {blocksCount.testnet2}</li>
              <li>Testnet 3: {blocksCount.testnet3}</li>
            </ul>
          </Alert>
          <Alert variant="outlined" severity="info" icon={false}>
            This address has been mapped to an Ethereum address for the public
            sale:
            <ul>
              <li>Testnet 1: {ethereumAddressFound.testnet1 ? "yes" : "no"}</li>
              <li>Testnet 2: {ethereumAddressFound.testnet2 ? "yes" : "no"}</li>
              <li>Testnet 3: {ethereumAddressFound.testnet3 ? "yes" : "no"}</li>
            </ul>
          </Alert>
        </>
      );
    }

    return (
      <Alert variant="outlined" severity="warning">
        This substrate account is not found.
      </Alert>
    );
  }, [errors.substrate?.account, values.result]);

  return (
    <Stack sx={{ width: "100%", gap: 2 }}>
      <TextField
        fullWidth
        label="SUBSTRATE ACCOUNT ON THE TESTNETS"
        id="substrate.account"
        name="substrate.account"
        onChange={handleChange}
        error={Boolean(errors.substrate?.account)}
        InputProps={{
          endAdornment: (
            <Button sx={{ paddingX: 2 }} type="submit">
              check
            </Button>
          ),
        }}
        helperText="A substrate account you've used as a validator on any of the Humanode testnets."
      />
      {result}
    </Stack>
  );
};

type TroubleshootingFormValues = {
  substrate: {
    account?: string;
  };
  result?:
    | {
        state: "found";
        data: SubstrateRecoverResponse;
      }
    | { state: "notFound" };
};
const initialTroubleshootingFormValues: TroubleshootingFormValues = {
  substrate: {},
};

const SubstrateForm: React.FC = () => {
  const { baseUrl } = useConfig();
  const validate = useCallback(async (values: TroubleshootingFormValues) => {
    if (!values.substrate.account) {
      return {
        substrate: {
          account: "substrate account is required",
        },
      };
    } else if (values.substrate.account.length !== 48) {
      return {
        substrate: {
          account: "substrate account has wrong format",
        },
      };
    }
  }, []);

  const onSubmit = useCallback(
    async (
      _values: TroubleshootingFormValues,
      { setFieldValue }: FormikHelpers<TroubleshootingFormValues>
    ) => {
      const values = _values as DeepRequired<TroubleshootingFormValues>;

      try {
        setFieldValue("result", {
          state: "found",
          data: await substrateRecover(baseUrl, values.substrate.account),
        });
      } catch (e) {
        setFieldValue("result", {
          state: "notFound",
        });
      }
    },
    [baseUrl]
  );

  return (
    <Formik<TroubleshootingFormValues>
      initialValues={initialTroubleshootingFormValues}
      validateOnChange={false}
      validateOnBlur={false}
      validate={validate}
      onSubmit={onSubmit}
    >
      <Box sx={{ width: "100%", maxWidth: "700px" }}>
        <Form>
          <Stack spacing={3} alignContent="center">
            <Stack spacing={1}>
              <Typography variant="body1">
                This form allows you to enter the Substrate address that was
                supposed to be used as a validator in any of the testnets and
                see if there are any blocks this validator paritcipated in.
              </Typography>
              <Typography variant="body2" marginBottom={1}>
                We will show you:
              </Typography>
              <ul>
                <li>
                  how many blocks this validator has participated in processing
                  in the testnets;
                </li>
                <li>
                  whether we have the mappings for this validator address to
                  an Ethereum address for use in the public sale (without
                  revealing the actual Ethereum address).
                </li>
              </ul>
            </Stack>
            <TroubleshootingFields />
          </Stack>
        </Form>
      </Box>
    </Formik>
  );
};

const Fields: React.FC = () => {
  const { values, errors, handleChange } = useFormikContext<FormValues>();
  const result = useMemo(() => {
    if (errors.ethereum?.address) {
      return (
        <Alert variant="outlined" severity="error" icon={<CloseIcon />}>
          <Typography>{errors.ethereum?.address}</Typography>
        </Alert>
      );
    }

    if (!values.result) return null;

    if (values.result.state === "found") {
      const {
        totalBlocksCount: totalCount,
        blocksCount: {
          testnet1: tn1Count,
          testnet2: tn2Count,
          testnet3: tn3Count,
        },
      } = values.result.data;

      return (
        <Alert variant="outlined" severity="success">
          <Typography>This address is in the public sale whitelist!</Typography>
          <Typography variant="body2" marginTop={1}>
            This validator participated in processing of {totalCount} blocks in
            total among all the testnets:
          </Typography>
          <ul>
            <li>Testnet 1: {tn1Count} blocks</li>
            <li>Testnet 2: {tn2Count} blocks</li>
            <li>Testnet 3: {tn3Count} blocks</li>
          </ul>
        </Alert>
      );
    }

    return (
      <Alert variant="outlined" severity="error" icon={<CloseIcon />}>
        <Typography>
          This ethereum address is not in the public sale whitelist.
        </Typography>
      </Alert>
    );
  }, [errors.ethereum, values.result]);

  return (
    <>
      <TextField
        fullWidth
        label="ETHEREUM ADDRESS YOU WILL USE ON THE PUBLIC SALE"
        placeholder="0x..."
        id="ethereum.address"
        name="ethereum.address"
        onChange={handleChange}
        error={Boolean(errors.ethereum?.address)}
        helperText="The ethereum address that you've submitted for the public sale whitelisting."
        InputProps={{
          endAdornment: (
            <Button sx={{ paddingX: 2 }} type="submit">
              check
            </Button>
          ),
        }}
      />
      {result}
    </>
  );
};

type FormValues = {
  ethereum: {
    address?: string;
  };
  result?:
    | {
        state: "found";
        data: EthereumCheckResponse;
      }
    | { state: "notFound" };
};
const initialFormValues: FormValues = {
  ethereum: {},
};

const EthereumForm: React.FC = () => {
  const { baseUrl } = useConfig();
  const validate = useCallback(async (values: FormValues) => {
    if (!values.ethereum.address) {
      return {
        ethereum: {
          address: "Ethereum address is required",
        },
      };
    } else if (!isHexString(values.ethereum.address, 20)) {
      return {
        ethereum: {
          address: "Ethereum address has a wrong format",
        },
      };
    }
  }, []);

  const onSubmit = useCallback(
    async (
      _values: FormValues,
      { setFieldValue }: FormikHelpers<FormValues>
    ) => {
      const values = _values as DeepRequired<FormValues>;
      try {
        setFieldValue("result", {
          state: "found",
          data: await ethereumCheck(baseUrl, values.ethereum.address),
        });
      } catch (e) {
        setFieldValue("result", {
          state: "notFound",
        });
      }
    },
    [baseUrl]
  );

  return (
    <Formik<FormValues>
      initialValues={initialFormValues}
      validateOnChange={false}
      validateOnBlur={false}
      validate={validate}
      onSubmit={onSubmit}
    >
      <Box sx={{ width: "100%", maxWidth: "700px" }}>
        <Form>
          <Stack spacing={3} alignContent="center">
            <Stack spacing={1}>
              <Typography variant="body1">
                This form allows you to lookup you Ethereum address in the list
                that we have prepared for the Public Sale Wave 1.
              </Typography>
              <Typography variant="body2" marginBottom={1}>
                The list is constructed based on:
              </Typography>
              <ul>
                <li>
                  the data that we have exported from the testnets on how many
                  blocks each validator have been participating in processing;
                </li>
                <li>
                  the mappings of testnet Substrate validator addresses to
                  Ethereum address that you have filled in earlier.
                </li>
              </ul>
            </Stack>
            <Fields />
            <Typography variant="body2" align="center">
              Scroll down if you want to verify how we are doing the lookup by
              checking if you validator address was accounted for.
            </Typography>
          </Stack>
        </Form>
      </Box>
    </Formik>
  );
};

const FormComponent: React.FC = () => {
  return (
    <Layout logo>
      <Stack>
        <EthereumForm />
        <Box marginTop="50vh" marginBottom="50vh">
          <SubstrateForm />
        </Box>
      </Stack>
    </Layout>
  );
};

const Configurations: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  return (
    <ConfigGuard
      uninit={ConfigLoading}
      pending={ConfigLoading}
      error={ErrorPage}
      ready={() => <>{children}</>}
    />
  );
};

const MainPage: React.FC = () => (
  <Configurations>
    <FormComponent />
  </Configurations>
);

export default MainPage;
